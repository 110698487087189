/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Global Styles */
body {
  @apply bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100;
  margin: 0;
  font-family: 'Inter', sans-serif;
}

a {
  @apply text-blue-500 hover:underline;
}

button {
  @apply focus:outline-none;
}
